import axios from "axios";
import { makeAutoObservable } from "mobx";


class ConnectionStatusStore {

    runningFistOnlineCheck: boolean
    isOnline: boolean

    constructor() {
        makeAutoObservable(this)
        this.isOnline = false
        this.runningFistOnlineCheck = true
        this.doFirstOnlineCheck()
    }

    private async doFirstOnlineCheck() {
        try {
            this.runningFistOnlineCheck = true;
            await this.checkIfOnline()
        } catch (err) {

        }finally{
            this.runningFistOnlineCheck = false
        }
    }

    private async checkIfOnline() {
        try {
            await axios.get('ping');
            this.isOnline = true
        } catch (error) {
            this.isOnline = false
        }
    }
}

export default ConnectionStatusStore