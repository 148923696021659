import axios from "axios";
import API_RESOURCES from "constants/service-resources";
import IConsultaMetaForm from "interfaces/app/IConsultaMetaForm";
import IFromToFilter from "interfaces/app/IFromToFilter";
import IResumenDesempeno from "interfaces/service/models/IResumenDesempeno";
import IResumenDesempeVisitador from "interfaces/service/models/IResumenDesempVisitador";
import IResumenMetaVisitadorMes from "interfaces/service/models/IResumenMetaVisitadorMes";
import IMetaMesRequest from "interfaces/service/requests/IMetaMesRequest";
import IRendimientoVisitadoresMensualRequest from "interfaces/service/requests/IRendimientoVisitadoresMensualRequest";
import IRendimientoVisitadoresRequest from "interfaces/service/requests/IRendimientoVisitadoresRequest";
import IRendimientoVisitadorRequest from "interfaces/service/requests/IRendimientoVisitadorRequest";
import IresumenMensualResponse from "interfaces/service/responses/IresumenMensualResponse";


export async function desempProductos(data: IFromToFilter) {
    const result = await axios.get<IResumenDesempeno[]>(API_RESOURCES.ESTADISTICAS.BASE + '/productos', {
        params: {
            ...data
        }
    })
    return result.data
}

export async function rendimientoVisitadores(data: IRendimientoVisitadoresRequest) {
    const result = await axios.get<IResumenDesempeno[]>(API_RESOURCES.ESTADISTICAS.BASE + '/rendimiento-visitadores', {
        params: {
            ...data
        }
    })
    return result.data
}

export async function rendimientoVisitadoresMensual(data: IRendimientoVisitadoresMensualRequest) {
    const result = await axios.get<IresumenMensualResponse[]>(API_RESOURCES.ESTADISTICAS.BASE + '/rendimiento-visitadores/por-mes', {
        params: {
            ...data
        }
    })
    return result.data
}

export async function rendimientoVisitador(data: IRendimientoVisitadorRequest) {
    const result = await axios.get<IResumenDesempeVisitador>(API_RESOURCES.ESTADISTICAS.BASE + '/rendimiento-visitador', {
        params: {
            ...data
        }
    })
    return result.data
}

export async function acciones(data: IFromToFilter) {
    const result = await axios.get<IResumenDesempeno[]>(API_RESOURCES.ESTADISTICAS.BASE + '/acciones', {
        params: {
            ...data
        }
    })
    return result.data
}

export async function metasMes(data: IConsultaMetaForm) {
    const metaMesRequest: IMetaMesRequest = {
        mes: data.mesAno.getMonth() + 1,
        ano: data.mesAno.getFullYear()
    }
    const result = await axios.get<IResumenMetaVisitadorMes[]>(API_RESOURCES.ESTADISTICAS.BASE + '/metas', {
        params: {
            ...metaMesRequest
        }
    })

    return result.data
}