import axios from "axios"
import API_RESOURCES from "constants/service-resources"
import ICliente from "interfaces/service/models/ICliente"
import IPaginatedResponse from "interfaces/service/models/IPaginatedResponse"
import IPaginatedRequest from "interfaces/service/requests/IPaginatedRequest"

export async function registrarCliente(data: ICliente){
    const result = await axios.post<ICliente>(API_RESOURCES.CLIENTES.BASE, data)
    return result.data
}

export async function getClientes(params: IPaginatedRequest){
    const result = await axios.get<IPaginatedResponse<ICliente>>(API_RESOURCES.CLIENTES.BASE, {
        params: {
            ...params
        }
    })
    return result.data
}

export async function getDatallesCliente(id: ICliente["id"]) {
    const result = await axios.get<ICliente>(API_RESOURCES.CLIENTES.BASE + '/' + id)
    return result.data
}

export async function getClientesResumidos() {
    const result = await axios.get<ICliente[]>(API_RESOURCES.CLIENTES.BASE + '/resumidos', {
    })
    return result.data
}

export async function update(params: {id: number,data: ICliente}){
    const result = await axios.put(API_RESOURCES.CLIENTES.BASE+'/'+params.id, params.data)
    return result
}

export async function destroy(id: number){
    const result = await axios.delete(API_RESOURCES.CLIENTES.BASE+'/'+id)
    return result
}

export async function exportarExcel(cliente_id: number){
    const result = await axios.get(API_RESOURCES.CLIENTES.BASE+'/exportar-excel', {
        params: {
            cliente_id
        },
        responseType: 'blob'
    })

    return result
}