import * as auth from './auth'
import * as visitadores from './visitadores'
import * as medicos from './medicos'
import * as actividades from './actividades'
import * as visitasHospitales from './visitas-hospitaes'
import * as clientes from './clientes'
import * as productos from './productos'
import * as estaditicas from './estadisticas'
import * as opciones from './opciones'
import * as metas from './metas'
import * as reportes from './reportes'
import * as sync from './sync'

const api = {
    auth,
    visitadores,
    medicos,
    actividades,
    visitasHospitales,
    clientes,
    productos,
    estaditicas,
    opciones,
    metas,
    reportes,
    sync
}

export default api 