import axios from 'axios';
import useTitle from 'hooks/use-title';
import React, { useState } from 'react';
import TEXTS from '../../constants/texts/text';
import useStore from '../../hooks/use-store';
import LoginCard from './card';
import LoginForm from './form';

const Login = () => {
  const [isLoginIn, setisLoginIn] = useState(false)
  const [loginError, setLoginError] = useState<string | undefined>(undefined)
  const { authStore } = useStore()

  useTitle("Inicio de sesión")

  async function onLoginClick(email: string, password: string) {
    try {
      setisLoginIn(true)
      setLoginError(undefined)
      await authStore.login(email, password)
    } catch (error) {
      if(axios.isAxiosError(error)){
        if(error.response && error.response.status === 401){
          setLoginError("Usuario o contraseña incorrecto")
          return
        }
      }
      setLoginError(TEXTS.ERRORS.ERROR_INESPERADO)
      setisLoginIn(false)
      throw error
    }
  }

  return <LoginCard>
    <LoginForm onLoginClick={onLoginClick} isLoginIn={isLoginIn} error={loginError} />
  </LoginCard>
};

export default Login;
