import AuthStore from "./auth-store";
import SyncStore from "./sync-store";
import ConnectionStatusStore from "./connection-status-store";

export class RootStore {

    authStore: AuthStore;
    syncStore: SyncStore;
    connectionStatusStore: ConnectionStatusStore;

    constructor(){
        this.authStore = new AuthStore(this);
        this.syncStore = new SyncStore(this);
        this.connectionStatusStore = new ConnectionStatusStore();
    }
}