const BASE_RESOURCES = {
    AUTH: "/auth",
    VISITADORES: "/visitadores",
    MEDICOS: "/medicos",
    ACTIVIDADES: "/actividades",
    VISITAS_HOSPITALES: "/visitas-hospitales",
    CLIENTES: "/clientes",
    PRODUCTOS: "/productos",
    ESTADISTICAS: "/estadisticas",
    OPCIONES: "opciones",
    METAS: "/metas",
    REPORTES: "/reportes",
    SYNC: "/sync"
}

const API_RESOURCES = {
    AUTH: {
        BASE: BASE_RESOURCES.AUTH,
    },
    VISITADORES: {
        BASE: BASE_RESOURCES.VISITADORES
    },
    MEDICOS: {
        BASE: BASE_RESOURCES.MEDICOS
    },
    ACTIVIDADES: {
        BASE: BASE_RESOURCES.ACTIVIDADES,
        ADMIN: BASE_RESOURCES.ACTIVIDADES + '/admin'
    },
    VISITAS_HOSPITALES: {
        BASE: BASE_RESOURCES.VISITAS_HOSPITALES
    },
    CLIENTES: {
        BASE: BASE_RESOURCES.CLIENTES,
    },
    PRODUCTOS: {
        BASE: BASE_RESOURCES.PRODUCTOS
    },
    ESTADISTICAS: {
        BASE: BASE_RESOURCES.ESTADISTICAS
    },
    OPCIONES: {
        BASE: BASE_RESOURCES.OPCIONES
    },
    METAS: {
        BASE: BASE_RESOURCES.METAS
    },
    REPORTES:{
        BASE: BASE_RESOURCES.REPORTES
    },
    SYNC: {
        BASE: BASE_RESOURCES.SYNC
    }
}

export default API_RESOURCES