import format from "date-fns/format"

export function dateToYYYMMDD(date: Date){
    return date.toISOString().slice(0, 10)
}

export function fullDateTimeAMPM(date: Date) {
    return format(date, "dd-MM-yyyy hh:mm aaaaa'm'")
}

export function appDateFormat(dateStr: string){
    const date= new Date(dateStr.replace(/-/g, '\/').replace(/T.+/, ''))
    return format(date, "dd-MM-yyyy")
}